import { MDXProvider } from "@mdx-js/react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import Accordion from '../components/Accordion'
import Card from '../components/Card'
import CardContainer from '../components/CardContainer'
import Carousel from '../components/Carousel'
import FactBox from '../components/FactBox'
import HeaderWithImage from '../components/HeaderWithImage'
import SectionTemplate from '../components/SectionTemplate'
import ShowMore from '../components/ShowMore'
import Slide from '../components/Slide'
import './example.scss'


export default function Example({data}) {
  const shortcodes = { Card, CardContainer, FactBox, Accordion, Carousel, Slide, HeaderWithImage, ShowMore}
  return (
    <div className="Example">
      <SectionTemplate title={data.mdx.frontmatter.title} image={data.mdx.frontmatter.image} >
        <MDXProvider components={shortcodes}>
          <MDXRenderer>
            {data.mdx.body}
          </MDXRenderer>
        </MDXProvider>
      </SectionTemplate>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    mdx(slug: {eq: "resources"}) {
      body
      frontmatter {
        title
        image
      }
    }
  }
`